<ion-header>
  <ion-toolbar color="tertiary" mode="ios">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" color="primary">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary" class="ion-text-left login-title" size="large">{{'LOGIN' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="login-div product-second-part">
    <ion-label class="ion-text-center" color="primary">{{'NAME' | translate}}</ion-label>
    <div class="input-div">
      <ion-input type="text" placeholder=". . . . . . . . . . . . ." #phone class="ion-no-padding ion-padding-vertical name-input"
        [(ngModel)]="name" type="string" required="true"></ion-input>
    </div>
    <ion-label class="ion-text-center" color="primary">{{'MOBILE' | translate}}</ion-label>
    <div class="input-div">
      <ion-input type="text" inputmode="numeric" pattern="[0-9]*" placeholder="0500000000" #phone class="ion-no-padding ion-padding-vertical mobile-input"
        [(ngModel)]="registerNum"
        maxlength="10"
        required="true" ></ion-input>
    </div>
    <ion-button (click)="phonelogin()" expand="block" [disabled]="!isvalid()" class="send-login-button" color="tertiary">
      {{'Send' | translate}} {{intervalNbr !== 60 ? intervalNbr : ''}}
    </ion-button>
  </div>
</ion-content>
