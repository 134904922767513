import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';
export enum NBR {
  nbr
}
@Injectable({
  providedIn: 'root',
})
export class CartService {
  data: any;
  private status: BehaviorSubject<number> = new BehaviorSubject(0);
  private statusSubscribed: BehaviorSubject<number> = new BehaviorSubject(0);
  constructor(
    public toast: ToastController,
    public translate: TranslateService,
    private st: StorageService
  ) {
    this.noOfItems();
    this.noOfSubscribedItems();
  }

  async setLocation(data, type) {
    await this.initCarts(type);
    const cart = await this.st.getItem(type === 'normal' ? 'cartItem' : 'cartSubscription');
    cart.location = data;
    return this.st.setItem(type === 'normal' ? 'cartItem' : 'cartSubscription', cart, true, Date.now() + (3600 * 1000 * 3));
  }

  async initCarts(type) {
    const cart = await this.st.getItem(type === 'normal' ? 'cartItem' : 'cartSubscription');
    return new Promise(resolve => {
      if (!(!!cart)) {
        this.st.setItem(type === 'normal' ? 'cartItem' : 'cartSubscription', {
          coupon: undefined,
          orderForDate: new Date(),
          items: [],
          location: undefined
        }, true, Date.now() + (3600 * 1000 * 3))
      }
      setTimeout(() => {
        resolve(true);
      }, 100);
    })
  }
  async add(product: any, type: string) {
    return this.st.getItem(type === 'normal' ? 'cartItem' : 'cartSubscription').then((cart) => {
      if (cart == null || !cart.items.length) {
        product.itemTotalPrice = product.Quantity * (Number(product.sizeOption.specialPrice) || Number(product.sizeOption.value));
        const itemInCart: any[] = [];
        itemInCart.push(product);
        return this.st.setItem(type === 'normal' ? 'cartItem' : 'cartSubscription', {
          coupon: undefined,
          orderForDate: new Date(),
          items: itemInCart,
          location: cart ? "location" in cart ? cart.location : undefined : undefined
        }, true, Date.now() + (3600 * 1000 * 3)).then((ff) => {
          if (type === 'normal') {
            this.noOfItems();
          } else {
            this.noOfSubscribedItems()
          }
        });
      } else {
        let isNew = true;
        cart.items.forEach((p: { productId: any; sizeOption: { pname: any; value: any; }; Quantity: number; itemTotalPrice: number; }) => {
          if (
            p.productId === product.productId &&
            p.sizeOption.pname === product.sizeOption.pname &&
            p.sizeOption.value === product.sizeOption.value
          ) {
            p.Quantity += product.Quantity;
            p.itemTotalPrice = p.Quantity * (Number(product.sizeOption.specialPrice) || Number(product.sizeOption.value));
            const halalaDiscountWithVAT = p.itemTotalPrice - Math.floor(p.itemTotalPrice);
            if (halalaDiscountWithVAT > 0.99 || halalaDiscountWithVAT < 0.012) {
              p.itemTotalPrice = Math.round(p.itemTotalPrice);
            }
            isNew = false;
          }
        });
        if (isNew) {
          product.itemTotalPrice = product.Quantity * (Number(product.sizeOption.specialPrice) || Number(product.sizeOption.value));
          cart.items.push(product);
        };
        return this.st.setItem(type === 'normal' ? 'cartItem' : 'cartSubscription', cart, true, Date.now() + (3600 * 1000 * 3)).then(() => {
          if (type === 'normal') {
            this.noOfItems();
          } else {
            this.noOfSubscribedItems()
          }
        });
      }
    });
  }

  public nbrOnChange(): Observable<NBR> {
    return this.status.asObservable();
  }

  public nbrOnChangeSubscription(): Observable<NBR> {
    return this.statusSubscribed.asObservable();
  }

  cartTotalValue(type: string) {
    return this.st.getItem(type === 'normal' ? 'cartItem' : 'cartSubscription').then((cartItems) => {
      let initPrice = 0;
      cartItems.items.forEach((item: { Quantity: any; sizeOption: { specialPrice: any; value: any; }; }) => {
        const quantity = item.Quantity;
        const sizeOption = !item.sizeOption.specialPrice ? item.sizeOption.value : item.sizeOption.specialPrice;
        const priceByQuantity = (quantity * sizeOption);
        initPrice = initPrice + priceByQuantity;
      });
      return initPrice;
    })
  }

  noOfItems() {
    return this.st.getItem('cartItem').then((cartItems) => {
      const nbr = !cartItems ? 0 :
        cartItems.items.reduce((acc: any, curr: { Quantity: any; }) => acc + curr.Quantity, 0);
      this.status.next(nbr);
      return nbr;
    });
  }

  noOfSubscribedItems() {
    return this.st.getItem('cartSubscription').then((cartItems) => {
      const nbr = !cartItems ? 0 :
        cartItems.items.reduce((acc: any, curr: { Quantity: any; }) => acc + curr.Quantity, 0);
      this.statusSubscribed.next(nbr);
      return nbr;
    });
  }

  public calculatePrice(tax: number, type = 'normal', coupon:any = undefined) {
    return this.st.getItem(type === 'normal' ? 'cartItem' : 'cartSubscription').then((cartItems) => {
      if (!!cartItems) {
        const obj: any = {};
        obj.subTotalPriceBeforeDiscount = cartItems.items.reduce(
          (acc: number, cart: any) => acc + (Number(cart.sizeOption.value) * cart.Quantity), 0);
          obj.subTotalPrice = cartItems.items.reduce((acc: any, { itemTotalPrice }: any) => acc + itemTotalPrice, 0);
          obj.deductedPrice = 0;
          obj.couponDiscount = 0;
          obj.couponDiscountPercentage = 0;

          if (typeof coupon !== "undefined") {
            const subTotalPrice = obj.subTotalPrice;
            const offer = subTotalPrice / 100 * coupon.offerPercentage
            obj.subTotalPrice = subTotalPrice - offer;
            obj.deductedAmountByCoupon = obj.subTotalPrice - subTotalPrice;
            obj.couponDiscountPercentage = coupon.offerPercentage;
        }
  
        obj.taxAmount = Number(((tax / 100) * obj.subTotalPrice));
        obj.grandTotal = (obj.subTotalPrice + obj.taxAmount);
        // let halalaDiscountWithVAT = obj.grandTotal - Math.floor(obj.grandTotal);
        // if (halalaDiscountWithVAT > 0.99 || halalaDiscountWithVAT < 0.012) {
        //   obj.grandTotal = Math.round(obj.grandTotal);
        //   halalaDiscountWithVAT = 0;
        // }
        // const halalaDiscountWithoutVAT = halalaDiscountWithVAT * 100 / (100 + tax);
        // obj.subTotalPrice -= halalaDiscountWithoutVAT;
        obj.taxAmount = Number(((tax / 100) * obj.subTotalPrice));
        obj.grandTotal = Number((obj.taxAmount + obj.subTotalPrice).toFixed(2));
        obj.deductedPrice = obj.subTotalPrice - obj.subTotalPriceBeforeDiscount;
        return obj;
      } else {
        return {
          subTotalPriceBeforeDiscount: 0,
          subTotalPrice: 0,
          deductedPrice: 0,
          couponDiscount: 0,
          couponDiscountPercentage: 0,
          deductedAmountByCoupon: 0,
          taxAmount: 0,
          grandTotal: 0,
        }
      }
    })
  }
}
