import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantService } from './constant.service';
import { StorageService } from './storage.service';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  getToken = () => this.st.getItem('token')
  token:any = ''


  constructor(
    private http: HttpClient,
    private constService: ConstantService,
    private st: StorageService
  ) {}

  login(user: any) {
    const body = JSON.stringify(user);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.constService.getBaseUrl() + 'auth/phone', body, { headers });
  }

  async get(path: any, secure: boolean, timestamp = 0, ...ids: any[] ) {
    if (!environment.production) {
      this.st.removeItem(path);
    }
    let res = await this.st.getItem(path);
    if (res === null) {
      const token = secure ? await this.getToken() || '' : ''
      const headers = token !== ''
        ? new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
        : new HttpHeaders().set('Content-Type', 'application/json');
        res = await firstValueFrom(this.http.get(`${this.constService.getBaseUrlApi()}${path}${ids[0] ? '/' + ids.join().replace(/,/g,'/') : ''}` , { headers }))
      if (timestamp > 0) {
        await this.st.setItem(path, res, true, timestamp);
      }
    }
    return res;
  }

  got(path: string, secure: { isIt: any; token: any; }, ...ids: any[]) {
    const headers = secure.isIt ? new HttpHeaders()
      .set('Authorization', secure.token || '')
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
        : new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(path , { headers });
  }

  async post(path: any, data: any, secure: boolean, ...ids: any[]) {
    const token = secure ? await this.getToken() || '' : ''
    const headers = token !== '' ?
      new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json') :
      new HttpHeaders().set('Content-Type', 'application/json');
    return firstValueFrom(this.http.post(`${this.constService.getBaseUrlApi()}${path}${ids[0] ? '/' + ids.join().replace(/,/g,'/') : ''}`, data, {
      headers
    }));
  }

  otpPhoneVerification(path: any, loginCodeNo: any, token: string) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json');
    return this.http.post(`${this.constService.getBaseUrlApi()}${path}`, { loginCodeNo }, { headers });
  }

  async put(path: any, data: any, secure: boolean, ...ids: any[]) {
    const token = secure ? await this.getToken() || '' : ''
    const headers = token !== '' ?
      new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json') :
      new HttpHeaders().set('Content-Type', 'application/json');
    return firstValueFrom(this.http.put(`${this.constService.getBaseUrlApi()}${path}${ids[0] ? '/' + ids.join().replace(/,/g,'/') : ''}`, data, { headers }));
  }

  async delete(path: any, secure: boolean, ...ids: any[]) {
    const token = secure ? await this.getToken() || '' : ''
    const headers = token !== '' ?
      new HttpHeaders().set('Authorization', token || '') :
      new HttpHeaders();
    return firstValueFrom(this.http.delete(`${this.constService.getBaseUrlApi()}${path}${ids[0] ? '/' + ids.join().replace(/,/g,'/') : ''}` , { headers }));
  }
}
