import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}
  async setItem(key: string, value: any, hasExpiry = false, timestamp = 0) {
    return await Preferences.set({
      key,
      value: JSON.stringify({
        data: value,
        hasExpiry,
        timestamp
      })
    });
  }

  async getItem(key: string): Promise<any> {
    const ret = await Preferences.get({ key });
    return new Promise((resolve) => {
      var value: any = ret.value ? JSON.parse(ret.value) : null;
      if (ret.value) {
        if (value.hasExpiry) {
          if (Date.now() > value.timestamp) {
            this.removeItem(key);
            return resolve(null);
          }
        }
      }
      return resolve(value ? value.data : null);
    })
  }
  async removeItem(key: string) {
    await Preferences.remove({ key });
  }
  async clear() {
    await Preferences.clear();
  }
}
