<ion-content>
  <div class="cupertino-pane-in down">
    <ion-text color="primary">
      <h1>{{"OTP" | translate}}</h1>
    </ion-text>
    <ng-otp-input (onInputChange)="onOtpChange($event)" (keydown.enter)="optLength === 4 ? dismissPane() : null"
      [config]="{length:4, allowNumbersOnly: true, inputClass: 'opt-input', containerClass: 'opt-container'}">
    </ng-otp-input>
    <ion-button expand="full" size="large" class="ion-margin-top" *ngIf="optLength === 4" (click)="dismissPane()"
      [disabled]="loadOpt" color="tertiary">
      <ion-label *ngIf="!loadOpt">{{'VERIFY' | translate}}</ion-label>
      <ion-spinner name="bubbles" *ngIf="loadOpt" style="font-size: 3rem;"></ion-spinner>
    </ion-button>
  </div>
</ion-content>
