import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor() { }

  nbrTranslate(v: any, to = 'en') {
    const nbr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    if (to === 'en') {
      const map: any = { '١': '1', '٢': '2', '٣': '3', '٤': '4', '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9', '٠': '0' };
      return v.split('').map((l: string) => nbr.indexOf(l) === -1 ? map[l] : l).join('');
    } else {
      const map2: any = { 1: '١', 2: '٢', 3: '٣', 4: '٤', 5: '٥', 6: '٦', 7: '٧', 8: '٨', 9: '٩', 0: '٠' };
      return v.split('').map((l: string) => nbr.indexOf(l) === -1 ? l : map2[l]).join('');
    }
  }
}
