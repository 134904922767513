import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActionSheetController, ModalController, ToastController } from '@ionic/angular';
import { GeneralService } from 'src/app/services/general.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login-step2.component.html',
  styleUrls: ['./login-step2.component.scss'],
})
export class LoginStep2Component implements  OnDestroy {
  @ViewChild('phone') phone: any;
  @Input() public temporaryToken: any;
  registerNum = '';
  name: any = '';
  timedout = false;
  interval: any;
  intervalNbr = 60;
  optLength = 0;
  opt!: number;
  loadOpt = false;
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private modal: ModalController,
    private services: GeneralService,
    public action: ActionSheetController,
    private toast: ToastController,
    public translate: TranslateService,
    private st: StorageService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  dismiss() {
    this.modal.dismiss();
  }

  onOtpChange(e: any) {
    this.optLength = e.length;
    if (this.optLength === 4) {
      this.opt = e;
    }
  }

  async dismissPane() {
    this.loadOpt = true;
    try {
      const result: any = await firstValueFrom(this.services.otpPhoneVerification('users/password/otpPhoneVerification', this.opt, this.temporaryToken))
      if (result.login) {
        const { token } = result;
        this.timedout = false;
        this.st.setItem('token', 'Bearer ' + token)
        this.modal.dismiss({ token });
      } else {
        this.loadOpt = false;
        this.translate.get('WRONG CODE').toPromise().then(async (message) => {
          (await this.toast.create({ message, color: 'danger', duration: 1500 })).present();
        });
      }
    } catch (err) {
      console.log('error' , err);
    }
  }
}
