import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActionSheetController, IonNav, ModalController } from '@ionic/angular';
import { GeneralService } from 'src/app/services/general.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoginStep2Component } from '../login-step2/login-step2.component';

@Component({
  selector: 'app-login',
  templateUrl: './login-step1.component.html',
  styleUrls: ['./login-step1.component.scss'],
})
export class LoginStep1Component implements  OnDestroy {
  @ViewChild('phone') phone: any;
  @Input() public isLogin: any;
  registerNum = '';
  name: any = '';
  timedout = false;
  interval: any;
  intervalNbr = 60;
  optLength = 0;
  opt!: number;
  loadOpt = false;
  temporaryToken: any;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private modal: ModalController,
    private services: GeneralService,
    public action: ActionSheetController,
    private f: FunctionsService,
    public translate: TranslateService,
    private nav: IonNav) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ionViewWillEnter() {
    setTimeout(async () => {
      this.phone.setFocus();
    }, 3000);
  }

  dismiss() {
    this.modal.dismiss();
  }
  isvalid = (): boolean => {
    const registerNum = this.f.nbrTranslate(this.registerNum);
    return registerNum.length === 10 && registerNum.split('')[0] === '0' && this.name.length >= 1;
  };

  async phonelogin() {
    if (!this.timedout) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.intervalNbr--;
        if (!this.intervalNbr) {
          clearInterval(this.interval);
          this.intervalNbr = 60;
          this.timedout = false;
        }
      }, 1000);
      try {
        const result: any = await firstValueFrom(this.services.login({ name: this.name, nbr: this.registerNum }))
        this.temporaryToken = result.token;
        this.timedout = true;
        this.nav.push(LoginStep2Component, {
          temporaryToken: result.token
        });
      } catch (error) {
        setTimeout(() => {
          this.timedout = false;
        }, 60000);
      }
    }
  }
}
